.cooperate {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  right: 0;
  bottom: 180px;
  z-index: 50;
  width: 60px;
  background: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
.cooperate .cooperateItem {
  position: relative;
  text-align: center;
  width: 100%;
  height: 60px;
  font-size: 10px;
  color: #666;
  transition: 0.5s;
  cursor: pointer;
}
.cooperate .cooperateItem:hover {
  color: #fff;
  background-color: #2878FF;
}
.cooperate .cooperateItem span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
  margin: 11px 0 4px;
}
.cooperate .cooperateItem:nth-child(1) span {
  background-image: url("../../assets/loginIndexNew/hz1_b.png");
}
.cooperate .cooperateItem:nth-child(1):hover span {
  background-image: url("../../assets/loginIndexNew/hz1_a.png");
}
.cooperate .cooperateItem:nth-child(2) span {
  background-image: url("../../assets/loginIndexNew/hz2_b.png");
}
.cooperate .cooperateItem:nth-child(2):hover span {
  background-image: url("../../assets/loginIndexNew/hz2_a.png");
}
.cooperate .cooperateItem:nth-child(3) span {
  background-image: url("../../assets/loginIndexNew/hz3_b.png");
}
.cooperate .cooperateItem:nth-child(3):hover span {
  background-image: url("../../assets/loginIndexNew/hz3_a.png");
}
.cooperate-single {
  bottom: 380px;
}
.cooperate-single .cooperateItem {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cooperate-single .cooperateItem:nth-child(1) span {
  width: 22px;
  height: 22px;
  margin: 0;
  background-image: url("../../assets/loginIndexNew/hz4_b.png");
}
.cooperate-single .cooperateItem:nth-child(1):hover span {
  background-image: url("../../assets/loginIndexNew/hz4_a.png");
}
 /deep/ .el-backtop {
  width: 60px;
  height: 60px;
  border-radius: 0;
  background: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
}
 /deep/ .el-backtop .cooperateItem {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  transition: 0.5s;
  cursor: pointer;
}
 /deep/ .el-backtop .cooperateItem:hover {
  background-color: #2878FF;
}
 /deep/ .el-backtop .cooperateItem span {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: cover;
}
 /deep/ .el-backtop .cooperateItem:nth-child(1) span {
  background-image: url("../../assets/loginIndexNew/hz_top.png");
}
 /deep/ .el-backtop .cooperateItem:nth-child(1):hover span {
  background-image: url("../../assets/loginIndexNew/hz_top_a.png");
}
.cooperateItem .cooperateItemInnerPop {
  width: 124px;
  text-align: center;
  position: absolute;
  top: 100vh;
  right: 60px;
  transform: translateY(-50%);
  padding-right: 10px;
  background: transparent;
  opacity: 0;
}
.cooperateItem .cooperateItemInnerPop .cooperateItemInnerPopInner {
  background: #FFF;
  color: #2878FF;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 3px 20px 1px rgba(0, 0, 0, 0.1);
}
.cooperateItem:hover .cooperateItemInnerPop {
  top: 50%;
  opacity: 1;
}
.picPoper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.picPoper img {
  width: 90px!important;
  height: 90px!important;
}
